import request from '@/utils/http'

// 获取该工地老板的工地列表
export function projectList(params) {
  return request({
    url: '/project/boss/list',
    method: 'get',
    params
  })
}

// 项目统计
export function projectStt(params) {
  return request({
    url: '/pc/finance/statistics',
    method: 'get',
    params
  })
}

// 结算统计
export function settlementStt(params) {
  return request({
    url: '/pc/finance/settle/statistics',
    method: 'get',
    params
  })
}

// 渣土场统计
export function slagStandStt(params) {
  return request({
    url: '/pc/finance/statistics',
    method: 'get',
    params
  })
}


// 土类型统计
export function soilTypeStt(params) {
  return request({
    url: '/pc/finance/statistics',
    method: 'get',
    params
  })
}

// 车队统计
export function truckStt(params) {
  return request({
    url: '/pc/finance/statistics',
    method: 'get',
    params
  })
}

// 渣土场项目列表
export function ztcQuery(params) {
  return request({
    url: '/ztc/query',
    method: 'get',
    params
  })
}


// 土类型列表
export function tlxQuery(params) {
  return request({
    url: '/tlx/query',
    method: 'get',
    params
  })
}


// 车队列表
export function fleetQuery(params) {
  return request({
    url: '/fleet/query',
    method: 'get',
    params
  })
}